<template>
  <div class="helper">
    <div class="helper-sections">
      <div
        v-for="(section, sectionKey) in sections"
        :key="sectionKey"
        class="helper-section"
      >
        <div class="helper-section-title">
          <strong>{{ $t(section.title) }}</strong>
        </div>
        <ul class="helper-section-list">
          <li
            v-for="(operator, key) in section.operators"
            :key="key"
            @click="copy(operator.template)"
          >
            <i class="fal fa-clipboard-list"></i>{{ $t(operator.title) }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from "@/components/Tools/helperFunctions";

export default {
  props: {
    sections: {
      type: Array,
      default: null
    }
  },
  methods: {
    copy(template) {
      copyToClipboard(template);
    }
  }
};
</script>
